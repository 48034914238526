.AdhocInput {
  flex: 1;

  .AdhocInput-body {
    display: flex;
  }

  .AdhocInput-resize {
    background-color: white;
    position: absolute;
    top: 13px;
    right: 13px;

    .AdhocInput-resize-button {
      background-color: transparent;
      border: none;
      padding: 0;

      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  .Input {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }

  .AdhocInput-title {
    display: flex;
    margin-bottom: 12px;
    height: 58px;

    .AdhocInput-title-content {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    .AdhocInput-title-number {
      width: 47px;
      height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 50%;
      font-size: 29px;
      font-weight: 700;
      font-family: 'Inter', sans-serif;
      color: var(--blue);
      margin-right: 16px;
    }

    h1 {
      margin: 0;
      font-weight: 700;
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      color: var(--super-dark-grey);
    }

    .AdhocInput-title-title {
      display: flex;
      align-items: center;

      .AdhocInput-title-line {
        margin-left: 16px;
        flex: 1;
        background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, var(--dark-grey) 0%);
        background-position: bottom;
        background-size: 4px 2px;
        background-repeat: repeat-x;
        height: 2px;
      }
    }

    .AdhocInput-title-buttons {
      display: flex;
      justify-content: space-between;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 14px;

      .AdhocInput-title-buttons-group {
        span {
          font-weight: 700;
          color: var(--blue);
          cursor: pointer;
        }

        &.AdhocInput-title-buttons-group-right > :last-child {
          margin-left: 23px;
        }
      }
    }
  }

  .AdhocInput-form {
    flex: 1;
    // max-height: calc(100vh - 248px);
    // overflow-y: auto;
    position: relative;
    background-color: white;
    border: 1px solid var(--medium-grey);
    padding: 40px;

    .AdhocInput-changed-warning {
      color: var(--orange);
      font-weight: 700;
      font-size: 16px;
      position: absolute;
      top: 12px;
    }

    .AdhocInput-group {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .AdhocInput-group-title {
        flex: 0 0 120px;
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: var(--super-dark-grey);
      }

      .AdhocInput-title-price {
        display: flex;
        flex-flow: column;
        grid-gap: 12px;

        > button {
          border: 1px solid var(--blue);
          border-radius: 10px;
          width: 40px;
          height: 40px;
          background-color: white;
          padding: 0;

          &:hover {
            background-color: var(--light-blue);
          }
        }
      }
      .AdhocInput-group-inputs-transmissie{
        
      }

      .AdhocInput-group-inputs {
        display: flex;

        &.AdhocInput-group-inputs-motorisatie {
          display: flex;

          > :last-child {
            display: flex;
            justify-content: center;
          }

          .AdhocInput-group-inputs-transmissie{
            flex:1;
          }
        }
        

        &.AdhocInput-group-inputs-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 16px;
          flex: 2;

          .AdhocInput-group-inputs-grid-nl {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
          }
        }

        &.AdhocInput-group-inputs-price {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 16px;
        }

        .AdhocInput-group-radio {
          h3, h4 {
            font-family: 'Inter', sans-serif;
            font-weight: 700;
            font-size: 16px;
            color: var(--dark-grey);
            margin: 0;
            margin-bottom: 16px;
          }

          h4 {
            font-size: 13px;
          }

          .AdhocInput-group-radios {
            display: flex;

            > :not(:first-child) {
              margin-left: 24px;
            }
          }
        }
      }
    }
  }

  .AdhocInput-modal-body {
    padding: 20px 26px 16px;

    .AdhocInput-modal-buttons {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
    }
  }

  &.AdhocInput-small {
    flex: unset;

    .AdhocInput-body {
      display: none;
    }

    .AdhocInput-title .AdhocInput-title-content {
      display: none;
    }

    .AdhocInput-title {
      cursor: pointer;
    }
  }
}
