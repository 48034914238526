.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #087CC4CC;
  display: none;
  justify-content: center;
  align-items: center;

  &.Modal-open {
    display: flex;
  }

  .Modal-body {
    background-color: white;
    border-radius: 10px;

    .Modal-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 26px 26px 20px;
      border-bottom: 1px solid var(--medium-grey);

      h1 {
        color: var(--super-dark-grey);
        font-size: 20px;
      }

      .Modal-close {
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
