.ScalingGraph-body {
  background-color: var(--light-grey);
  color: var(--super-dark-grey);
  padding: 65px 8px 36px;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: center;

  .ScalingGraph-column {
    width: 14%;
    padding: 5px 0;
    border-left: 4px solid var(--super-dark-grey);

    .ScalingGraph-innerblock {
      position: relative;
      text-align: center;
      width: 100%;
      padding: 5px 0;
      height: 25px;

      .ScalingGraph-nominal {
        position: absolute;
        top: -25px;
        left:-32px;
      }
    
      .ScalingGraph-percentage {
        position: absolute;
        bottom: -25px;
        left:-15px;
      }
    
      .ScalingGraph-price {
        position: absolute;
        top: -50px;
        width: 100%;
        text-align: center;
        font-weight: bold;
      }
    
      .ScalingGraph-line {
        position: absolute;
        top: -30px;
        height: 30px;
        border-left: 2px solid var(--super-dark-grey);
        border-right: 2px solid var(--super-dark-grey);
        width: 0px;
        left: 50%;
      }
    }
  }
  :last-child {
    width: 1px;
  }
  
}