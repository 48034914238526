.Select {
  position: relative;

  .Select-current {
    border: 1px solid var(--medium-grey);
    border-radius: 10px;
    padding: 0 16px;
    height: 48px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    &:hover {
      background-color: var(--light-blue);
    }

    &.Select-current-active {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: -1px;
        bottom: -1px;
        left: -1px;
        top: -1px;
        border: 2px solid var(--border-blue);
        border-radius: 10px;
      }
    }

    p {
      font-size: 14px;
    }

    .Select-current-caret {
      margin-left: 4px;
    }
  }

  .Select-options {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 10px #04477029;
    padding: 12px 0;
    z-index: 100;

    .Select-option {
      cursor: pointer;
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0 16px;

      &.Select-option-focussed {
        background-color: var(--light-blue);
      }

      &.Select-option-disabled {
        background-color: var(--light-grey);
        cursor: default;
        p {
          color: var(--medium-dark-grey);
        }
      }

      p {
        font-size: 14px;
      }

      .Select-option-check {
        margin-left: 4px;
      }
    }
  }

  .Select-label {
    font-family: 'Inter', sans-serif;
    color: var(--dark-grey);
    font-weight: 700;
    font-size: 13px;
  }

  .Select-small {
    height: 40px;
  }

  select {
    display: none;
  }
}
