.FacetedSelect {
  position: relative;
  width: 100%;

  .FacetedSelect-current {
    border: 1px solid var(--medium-grey);
    border-radius: 10px;
    padding: 0 16px;
    height: 48px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    &:hover {
      background-color: var(--light-blue);
    }

    &.FacetedSelect-current-active {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: -1px;
        bottom: -1px;
        left: -1px;
        top: -1px;
        border: 2px solid var(--border-blue);
        border-radius: 10px;
      }
    }

    p {
      font-size: 14px;
    }

    .FacetedSelect-current-caret {
      margin-left: 4px;
    }
  }

  .FacetedSelect-options {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 10px #04477029;
    padding: 12px 0;
    z-index: 1000;
    overflow-y: auto;

    .FacetedSelect-option {
      cursor: pointer;
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0 16px;

      &.FacetedSelect-option-focussed {
        background-color: var(--light-blue);
      }

      &.FacetedSelect-option-disabled {
        background-color: var(--light-grey);
        cursor: default;
        p {
          color: var(--medium-dark-grey);
        }
      }

      p {
        font-size: 14px;
      }

      .FacetedSelect-option-check {
        margin-left: 4px;
      }
    }

    
  }

  .FacetedSelect-blockList-options {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 10px #04477029;
    padding: 12px 0;
    z-index: 1000;
    min-width: inherit;
    padding: 5px;
    box-sizing: content-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
    max-height: 200px;
    overflow-y: auto;

    .FacetedSelect-blockOption {
      cursor: pointer;
      //height: 46px;
      display: flex;
      align-items: center;
      padding: 0 2px;
      background-color: #d9d9d9;
      padding: 4px;
      border-radius: 4px;

      &.FacetedSelect-blockOption-focussed {
        background-color: var(--light-blue);
      }

      &.FacetedSelect-blockOption-disabled {
        background-color: var(--light-grey);
        cursor: default;
        p {
          color: var(--medium-dark-grey);
        }
      }

      p {
        font-size: 14px;
      }

      .FacetedSelect-blockOption-check {
        margin-left: 4px;
      }
    }

  }

  .FacetedSelect-label {
    font-family: 'Inter', sans-serif;
    color: var(--dark-grey);
    font-weight: 700;
    font-size: 13px;
  }

  .FacetedSelect-small {
    height: 40px;
  }

  select {
    display: none;
  }
  
  .Input-status {
    display: flex;
    justify-content: flex-end;
    width: 20px;

    button {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid var(--medium-grey);
      cursor: pointer;
      padding: 0;
      background-color: var(--light-grey);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover, &:focus {
        background-color: white;
      }
    }
  }

  &:hover {
    .Input-status {
      .Input-valid-icon {
        display: none;
      }
      .Input-clear {
        display: initial;
      }
    }
  }
}
