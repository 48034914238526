.Header {
  display: flex;
  align-items: center;
  background-color: var(--blue);
  height: 80px;
  padding: 0 80px;
  position: relative;

  .Header-fill {
    flex: 1;
  }

  .Header-nav {
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    pointer-events: none;

    nav {
      pointer-events: initial;
      background-color: white;
      display: flex;
      border-radius: 10px;
      border: 1px solid var(--border-blue);
    }

    .Header-nav-button {
      padding: 0 24px;
      height: 48px;
      font-size: 16px;
      font-weight: 700;
      color: var(--dark-grey);
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      &.Header-nav-button-active {
        background-color: var(--blue);
        border: 1px solid var(--border-blue);
        color: white;
      }
    }
  }

  .Header-extra-nav {
    a {
      color: white;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      padding: 15px 20px;
      margin: 0 10px;
    }
  }

  .Header-help {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 32px;
  }

  .Header-user {
    .Header-user-logo {
      min-width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      grid-gap: 4px;

      .Header-user-image {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        .Header-user-image-placeholder {
          width: 100%;
          height: 100%;
          background-color: #aaa;
        }

      }

      p {
        max-width: 100px;
        margin: 0;
        color: white;
        font-size: 12px;
        text-align: left;
      }
    }
  }

  .Header-country-select {
    width: 172px;
  }
}
