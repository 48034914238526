.AdHoc {
  background-color: var(--background-grey);
  min-height: 100vh;

  .AdHoc-body {
    padding: 22px 80px;
    display: flex;

    > :not(:first-child) {
      margin-left: 16px;
    }
  }
}
