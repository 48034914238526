.Faq {
    background-color: var(--medium-light-grey);
    min-height: 100vh;
  
    .Faq-body {
      display: flex;
        flex-flow: column;
  
      > :not(:first-child) {
        margin-left: 16px;
      }

      .Faq-body-section {
        display: flex;
        justify-content: center;
        padding: 40px 0 80px;
        background-color: var(--light-grey);
  
        &:nth-child(even) {
          background-color: var(--medium-light-grey);
        }
  
        &:first-child {
          background-color: white;
        }
      }
  
      .Faq-body-center {
        width: 792px;
        margin: 0 80px;
  
        > p {
          font-size: 18px;
        }
      }

      h2 {
        margin-bottom: 25px;
      }
      ul {
        li{
            padding: 4px 0;
            a{
                color: var(--blue);
                
            }
        }
      }
    }

    
  }
  