.Dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .Dropdown-button {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .Dropdown-options {
    display: none;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    min-width: 100px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 10px #04477029;
    z-index: 100;
    padding: 12px 0;

    &.Dropdown-options-active {
      display: initial;
    }

    button {
      background-color: transparent;
      border: none;
      height: 46px;
      width: 100%;
      padding: 0;

      p {
        font-size: 14px;
      }

      &:hover {
        background-color: var(--light-blue);
      }
    }

    .Dropdown-option-text {
      height: 46px;
      padding: 0 8px;
      font-size: 12px;
      color: var(--medium-dark-grey);
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
    }
  }
}
