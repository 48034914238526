.SearchSelect {
  position: relative;

  .SearchSelect-current {
    border: 1px solid var(--medium-grey);
    border-radius: 10px;
    padding: 0 16px;
    height: 48px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    &:hover {
      background-color: var(--light-blue);
    }

    &.SearchSelect-current-active {
      border: 2px solid var(--border-blue);
    }

    p {
      font-size: 14px;
    }

    .SearchSelect-current-caret {
      margin-left: 4px;
    }
  }

  .SearchSelect-options {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 10px #04477029;
    padding: 12px 0;
    z-index: 100;
    max-height: 400px;
    overflow-y: auto;

    .SearchSelect-option {
      cursor: pointer;
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0 16px;

      &.SearchSelect-option-focussed {
        background-color: var(--light-blue);
      }

      p {
        font-size: 14px;
      }

      .SearchSelect-option-check {
        margin-left: 4px;
      }
    }
  }

  select {
    display: none;
  }
}
