.AdhocResult-Results-PriceAnalytics {
    height: 500px;
    padding-top: 10px;
  }
  
  .AdhocResult-Results-PriceAnalytics-graph {
    background-color: var(--light-grey);
    height: 500px;
    min-height: 500px;
    padding: 8px;
    max-width: calc(100% - 20px);
  }
  
  .tooltip{
    background-color: var(--light-grey);
    padding:8px;
    border: 1px solid var(--dark-grey);
  }
  .MarketAnalysis-Car-InfoColumn-Sub{
    font-size: 12px;
  }
  
  .picture {
      width: 0px;
      img {
        height: 50px;
        margin-top: 2px;
        margin-left: 2px;
      }
    }