.InmotivModal {
  display: flex;
  min-width: 1000px;
  max-width: 1000px;

  .InmotivModal-form {
    flex: 0 0 246px;
    border-right: 1px solid var(--medium-grey);
    padding: 22px 12px 30px 28px;
    overflow-y: auto;
    max-height: 600px;

    .InmotivModal-form-content {
      > * {
        margin-top: 8px;
      }

      .InmotivModal-form-title {
        font-size: 13px;
        font-weight: 700;
        color: var(--dark-grey);
      }

      .InmotivModal-form-input {
        border: 1px solid medium-grey;
        border-radius: 10px;
      }
    }
  }

  .InmotivModal-results {
    width: 736px;
    display: flex;
    flex-flow: column;
    min-height: 600px;

    .InmotivModal-results-list {
      flex: 1;
      padding: 22px 28px 0 10px;
    }

    .InmotivModal-results-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 18px 28px;
    }

    .InmotivModal-result-stats {
      font-size: 12px;
      font-weight: 400;
    }

    .InmotivModal-result-item {
      background-color: var(--medium-light-grey);
      height: 50px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      font-size: 14px;
      cursor: pointer;

      &.InmotivModal-result-item-selected {
        background-color: var(--light-blue);
        color: var(--blue);
        border: 2px solid var(--blue);
      }
    }
  }

  #list-scroll {
    overflow-y: scroll;
    max-height: 600px;
  }
}
