.InputToggle-root {
  height: 30px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(--super-dark-grey);

  span {
    cursor: pointer;
  }
}

.InputToggle-box {
  position: relative;
  height: 16px;
  width: 40px;
  border: 1px solid var(--medium-grey);
  background-color: var(--medium-light-grey);
  border-radius: 12px;
  transition: background 0.2s ease-in-out;

  .InputToggle-circle {
    cursor: pointer;
    position: absolute;
    top: -3px;
    width: 20px;
    height: 20px;
    background-color: var(--light-grey);
    border: 1px solid var(--medium-light-grey);
    box-shadow: 0px 1px 2px #a7a7a7;
    border-radius: 50%;
    transition: left 0.2s ease-in-out;
  }

  &.center {
    .InputToggle-circle {
      left: 9px;
    }
  }

  &.red {
    background-color: var(--red);
    .InputToggle-circle {
      left: -3px;
    }
  }

  &.green {
    background-color: var(--green);
    .InputToggle-circle {
      left: 23px;
    }
  }

  &.left {
    background-color: var(--blue);
    .InputToggle-circle {
      left: -3px;
    }
  }

  &.right {
    background-color: var(--blue);
    .InputToggle-circle {
      left: 23px;
    }
  }
}
