.Home {
  display: flex;
  flex-flow: column;

  .Home-info {
    display: flex;
  }

  .Home-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
  }

  .Home-header-info {
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 0 86px;
    background-color: white;
  }

  .Home-info-group {
    display: flex;
    align-items: center;
    margin-right: 28px;
    text-decoration: none;

    svg {
      margin-right: 8px;
    }
  }

  .Home-services-button {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .Home-header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 78px;
    background-color: var(--blue);
    padding: 0 86px;

    .Home-nav-buttons {
      display: flex;

      a {
        text-decoration: none;
        color: white;
        font-size: 18px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;

        &:not(:first-child) {
          margin-left: 51px;
        }

        &.Home-nav-buttons-active {
          font-weight: 900;
        }
      }
    }
  }

  .Home-login {
    display: flex;
    align-items: center;
    height: 100%;

    > *:not(:last-child) {
      margin-right: 8px;
      width: 180px;
    }
  }

  h1 {
    margin: 0;
    margin-bottom: 48px;
    font-family: 'Inter', sans-serif;
    color: var(--blue);
    font-size: 28px;
    font-weight: 700;
  }

  .Home-body {
    display: flex;
    flex-flow: column;
    margin-top: 126px;

    .Home-img-resize {
      width: #{"min(790px, 100%)"};
    }

    .Home-body-section {
      display: flex;
      justify-content: center;
      padding: 40px 0 80px;
      background-color: var(--light-grey);

      &:nth-child(even) {
        background-color: var(--medium-light-grey);
      }

      &:first-child {
        background-color: white;
      }
    }

    .Home-body-center {
      width: 792px;
      margin: 0 80px;

      > p {
        font-size: 18px;
      }
    }

    .Home-body-certified {
      display: flex;
      align-items: center;

      .Home-body-certified-text {
        margin: 0 40px;
      }
    }

    .Home-body-services {
      img {
        margin: 30px 0;
      }
    }
  }

  // form {
  //   background-color: white;
  //   padding: 48px 36px;

  //   > :first-child {
  //     margin-bottom: 26px;
  //   }

  //   > :last-child {
  //     margin-top: 32px;
  //   }
  // }

  // .Home-form-button {
  //   height: 53px;
  //   width: 124px;
  // }

  // .Home-form-grid {
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  //   grid-gap: 24px;
  // }

  // .Home-form-center {
  //   display: flex;
  //   justify-content: center;

  //   .Home-form-select {
  //     width: 138px;
  //   }
  // }

  .Home-footer {
    display: flex;
    justify-content: center;
    height: 135px;
    background-color: var(--super-dark-grey);

    .Home-footer-center {
      width: 792px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    p {
      color: white;
    }
  }

  .Home-anchor-target {
    position: relative;
    top: -164px;
  }
}

@media screen and (max-width: 1030px) {
  .Home {
    .Home-login {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .Home {
    .Home-header-info, .Home-header-nav {
      padding: 0 36px;
    }

    .Home-body {
      .Home-body-center {
        width: unset;
        margin: 0 40px;
      }

      .Home-body-certified {
        flex-flow: column;

        .Home-body-certified-text {
          margin: 20px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 530px) {
  .Home {
    .Home-header-info {
      padding: 0 16px;

      .Home-info-group {
        margin-right: 0;

        &:first-child {
          margin-right: 8px;
        }
      }

      p {
        font-size: 16px;
      }
    }

    .Home-header-nav {
      padding: 0 16px;
      justify-content: unset;

      .Home-nav-buttons {
        a {
          font-size: 16px;
          margin-left: 16px!important;
        }
      }
    }

    .Home-body {
      .Home-body-section {
        padding: 40px 0;
      }

      .Home-body-center {
        min-width: 375px;
        margin: 0 16px;
      }

      .Home-img-resize {
        width: 100%;
      }

      p {
        font-size: 16px;
      }
    }

    // form {
    //   .Home-form-grid {
    //     grid-template-columns: 1fr;

    //     .Home-form-blank {
    //       display: none;
    //     }
    //   }
    // }
  }
}
