.BatchChoose {
  flex: 1;
  display: flex;
  flex-flow: column;
  max-width: 800px;

  .BatchChoose-title {
    display: flex;

    .BatchChoose-title-circle {
      width: 47px;
      height: 47px;
      min-width: 47px;
      min-height: 47px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Inter', sans-serif;
      color: var(--blue);
      font-weight: 700;
      font-size: 29px;
      margin-right: 16px;
    }

    .BatchChoose-title-content {
      flex: 1;

      h1 {
        color: var(--super-dark-grey);
        font-size: 20px;
        display: flex;
        align-items: center;
      }

      p {
        font-size: 14px;
      }

      .BatchChoose-title-line {
        margin-left: 16px;
        flex: 1;
        background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, var(--dark-grey) 0%);
        background-position: bottom;
        background-size: 4px 2px;
        background-repeat: repeat-x;
        height: 2px;
      }
    }
  }

  .BatchChoose-body {
    flex: 1;
    margin-top: 11px;
    background-color: white;

    .BatchChoose-buttons {
      display: grid;
      grid-gap: 14px;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: 1px solid var(--medium-light-grey);
      padding: 24px;

      .BatchChoose-button {
        height: 80px;
        border: 1px solid var(--medium-grey);
        border-radius: 10px;
        display: flex;
        cursor: pointer;
        background-color: transparent;
        padding: 0;

        .BatchChoose-button-circle {
          width: 80px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid var(--medium-grey);
        }

        .BatchChoose-button-text {
          height: 100%;
          flex: 1;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;

          p {
            color: var(--dark-grey);
            font-size: 14px;
            font-weight: 700;
          }

          svg {
            margin-bottom: 10px;
          }
        }

        &.BatchChoose-button-active {
          background-color: var(--super-light-blue);
          border-color: var(--blue);

          .BatchChoose-button-circle {
            border-right-color: var(--blue);
          }

          .BatchChoose-button-text {
            p {
              color: var(--blue);
            }

            svg {
              fill: var(--blue);
            }
          }
        }

        &:disabled {
          cursor: default;
          background-color: var(--medium-light-grey);
        }

      }
    }

    .BatchChoose-email {
      padding: 24px;
      border-bottom: 1px solid var(--medium-light-grey);

      .BatchChoose-email-input {
        min-width: 200px;
        width: 50%;
      }
    }

    .BatchChoose-start {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px;
    }
  }
}
