.AdhocChoose {
  .AdhocChoose-title {
    display: flex;
    margin-bottom: 12px;
    height: 58px;

    .AdhocChoose-title-number {
      width: 47px;
      min-width: 47px;
      height: 47px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--blue);
      font-size: 29px;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      margin-right: 16px;
    }

    h1 {
      margin: 0;
      font-weight: 700;
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      color: var(--super-dark-grey);
    }

    .AdhocChoose-title-title {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      font-family: 'Inter', sans-serif;
      color: var(--super-dark-grey);
      font-size: 14px;

      h1 {
        display: flex;
        align-items: center;

        .AdhocChoose-title-line {
          margin-left: 16px;
          flex: 1;
          background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, var(--dark-grey) 0%);
          background-position: bottom;
          background-size: 4px 2px;
          background-repeat: repeat-x;
          height: 2px;
        }
      }
    }
  }

  .AdhocChoose-list {
    background-color: white;
    border: 1px solid var(--medium-grey);
    display: flex;
    flex-flow: column;

    .AdhocChoose-button-text {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: var(--super-dark-grey);
      margin-right: 16px;

      &.AdhocChoose-button-loading {
        display: flex;
        align-items: center;
        position: relative;
        span {
          opacity: 0;
        }
        .AdhocChoose-button-spinner {
          position: absolute;
        }
      }

      .AdhocChoose-recalc-message {
        color: var(--dark-grey);
        font-size: 14px;
        font-weight: 500;
        position: absolute;
      }
    }

    button {
      background-color: white;
      border: none;
      padding: 20px;
      color: var(--super-dark-grey);
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      &:not(:last-child) {
        border-bottom: 1px solid var(--medium-grey);
      }

      &:disabled {
        cursor: default;
        background-color: var(--light-grey);

        .AdhocChoose-button-text {
          color: #C2C2C2;

          .AdhocChoose-recalc-message {
            color: var(--medium-grey);
          }
        }

        svg {
          fill: #C2C2C2;
        }
      }
    }

    .AdhocChoose-button-active {
      background-color: #087CC41A;
      position: relative;

      .AdhocChoose-button-text {
        color: var(--blue);
      }

      svg {
        fill: var(--blue);
      }

      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: -1px;
        border: 1px solid var(--blue)
      }
    }
    .AdhocChoose-sub-item {
      padding: 10px;
      background-color: #087CC41A;
      color: var(--blue);
      position: relative;
      font-size: small;

      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: -1px;
        border: 1px solid var(--blue);
        border-left: 5px solid var(--blue);
        pointer-events: none;
      }
    }
  }
}
