.BatchResult {
  flex: 0 0 289px;
  display: flex;
  flex-flow: column;

  .BatchResult-title {
    display: flex;

    .BatchResult-title-circle {
      width: 47px;
      height: 47px;
      min-width: 47px;
      min-height: 47px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Inter', sans-serif;
      color: var(--blue);
      font-weight: 700;
      font-size: 29px;
      margin-right: 16px;
    }

    .BatchResult-title-content {
      h1 {
        color: var(--super-dark-grey);
        font-size: 20px;
        display: flex;
        align-items: center;
      }

      // p {
      //   font-size: 14px;
      // }
    }
  }

  .BatchResult-body {
    flex: 1;
    background-color: white;
    padding: 20px;
    margin-top: 11px;
    display: flex;

    .BatchResult-email {
      .BatchResult-progress {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-bottom: 16px;

        .BatchResult-progress-bar {
          height: 16px;
          width: 200px;
          background-color: var(--light-green);
          border-radius: 8px;
          margin-bottom: 8px;
        }
      }

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .BatchResult-download {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;

      p {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 700;
        color: var(--super-dark-grey);
      }
    }
  }
}
