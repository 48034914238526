.Spinner {
  display: flex;
  align-items: center;

  svg {
    animation: 3s linear infinite spin;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
