.BatchInput {
  flex: 1;
  display: flex;
  flex-flow: column;

  > input {
    display: none;
  }

  .BatchInput-title {
    display: flex;

    .BatchInput-title-circle {
      width: 47px;
      height: 47px;
      min-width: 47px;
      min-height: 47px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Inter', sans-serif;
      color: var(--blue);
      font-weight: 700;
      font-size: 29px;
      margin-right: 16px;
    }

    .BatchInput-title-content {
      flex: 1;

      h1 {
        color: var(--super-dark-grey);
        font-size: 20px;
        display: flex;
        align-items: center;
      }

      p {
        font-size: 14px;
      }

      .BatchInput-title-line {
        margin-left: 16px;
        flex: 1;
        background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, var(--dark-grey) 0%);
        background-position: bottom;
        background-size: 4px 2px;
        background-repeat: repeat-x;
        height: 2px;
      }
    }
  }

  .BatchInput-body {
    flex: 1;
    margin-top: 11px;
    background-color: white;
    padding: 28px;

    .BatchInput-upload {
      background-color: var(--super-light-blue);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 185px;

      > svg {
        opacity: 0.7;
      }

      p {
        font-size: 16px;
        font-weight: 700;
        color: var(--blue);
        opacity: 0.7;
        margin: 12px 0;
      }

      > div {
        display: flex;
        flex-flow: column;
        align-items: center;
      }
    }

    .BatchInput-templates {
      margin-top: 24px;
      display: flex;
      flex-flow: column;
      align-items: center;

      > p {
        margin-bottom: 26px;
        font-size: 14px;
        font-weight: 400;
        color: var(--super-dark-grey);
      }

      .BatchInput-buttons {
        display: flex;

        button {
          background-color: transparent;
          border: none;
          color: var(--blue);
          font-size: 14px;
          font-weight: 700;
          display: flex;
          align-items: center;
          padding: 0;

          svg {
            margin-left: 13px;
          }

          &:last-child {
            margin-left: 55px;
          }
        }
      }
    }

    .BatchInput-complete {
      height: 100%;
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      h2 {
        font-size: 16px;
        color: var(--super-dark-grey);
        margin-bottom: 15px;
      }

      .BatchInput-info {
        width: calc(100% - 32px);
        background-color: var(--light-green-more-transparent);
        padding: 16px;
        display: flex;
        align-items: center;

        .BatchInput-info-left {
          flex: 1;
        }

        p {
          color: var(--light-green);
          font-size: 14px;
        }

        .BatchInput-info-text {
          margin-bottom: 8px;
        }

        .BatchInput-info-text-extra {
          opacity: 0.7;
        }
      }

      button {
        background-color: transparent;
        border: none;
        padding: 0;
        color: var(--blue);
        font-weight: 700;
        font-size: 14px;
        margin-top: 15px;
      }

      .BatchInput-fill {
        flex: 1;
      }

    }
  }
}
