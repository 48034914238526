.GetPriceModal-input-body {
  position: relative;

  .GetPriceModal-spinner {
    position: absolute;
    top: 0;
    right: -10px;
    left: -10px;
    bottom: 0;
    background-color: #ffffff80;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
}

.GetPriceModal-info {
  margin: 0;
  display: grid;
  grid: auto / 100px auto;
  margin-bottom: 16px;

  > dt, > dd {
    display: inline;
  }

  > dt {
    font-weight: bold;
    color: var(--dark-grey);
    font-size: 13px;
  }

  > dd {
    color: var(--super-dark-grey);
    font-size: 13px;
  }
}

.GetPriceModal-input-row {
  display: flex;
  grid-gap: 16px;
}
