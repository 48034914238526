.ModelModal {
  display: flex;
  min-width: 1000px;
  max-width: 1000px;

  .ModelModal-form {
    flex: 0 0 246px;
    border-right: 1px solid var(--medium-grey);
    padding: 22px 12px 30px 28px;

    .ModelModal-form-content {
      >* {
        margin-top: 8px;
      }

      .ModelModal-form-title {
        font-size: 13px;
        font-weight: 700;
        color: var(--dark-grey);
      }

      .ModelModal-form-input {
        border: 1px solid medium-grey;
        border-radius: 10px;
      }
    }
  }

  .ModelModal-results {
    width: 736px;
    display: flex;
    flex-flow: column;
    min-height: 600px;

    .ModelModal-results-top {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .ModelModal-results-col {
        padding: 22px 28px 0 10px;

        >* {
          margin-top: 8px;
        }

        .ModelModal-form-title {
          font-size: 13px;
          font-weight: 700;
          color: var(--dark-grey);
        }

        .ModelModal-form-input {
          border: 1px solid medium-grey;
          border-radius: 10px;
        }
      }

      .ModelModal-results-top-column1 {
        grid-area: 1 / 3 / 2 / 4;
      }

      .ModelModal-results-top-column2 {
        grid-area: 1 / 2 / 2 / 3;
      }

      .ModelModal-results-top-column3 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .ModelModal-form-totalResults {
        background-color: var(--blue);
        border-radius: 10px;
        color: white;
        height: 20px;
        padding: 10px 24px;
        font-size: 18px;
        font-weight: 700;
        margin-top: 4px;
      }
    }

    .ModelModal-results-search {
      padding: 10px 25px 10px 10px;
      p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }

    .ModelModal-results-list {
      flex: 1;
      padding: 22px 28px 0 10px;
    }
    .ModelModal-results-list-loading{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      background-color: var(--medium-light-grey);
      height: 50px;
      padding: 0 18px;
      .Spinner {
        margin-right: 20px;
      }
    }

    .ModelModal-results-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 18px 28px;
    }

    .ModelModal-result-stats {
      font-size: 12px;
      font-weight: 400;
    }

    .ModelModal-result-item {
      background-color: var(--medium-light-grey);
      height: 50px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      font-size: 14px;
      cursor: pointer;

      &.ModelModal-result-item-selected {
        background-color: var(--light-blue);
        color: var(--blue);
        border: 2px solid var(--blue);
      }
    }
  }

  #list-scroll {
    overflow-y: scroll;
    height: 450px;
  }
}