.Batch {
  background-color: var(--medium-light-grey);
  min-height: 100vh;

  .Batch-body {
    padding: 22px 80px;
    display: flex;

    > :not(:first-child) {
      margin-left: 16px;
    }
  }
}
