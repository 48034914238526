.Input {
  > :nth-child(2) {
    margin-top: 4px;
  }

  .Input-container {
    background-color: white;
    border: 1px solid var(--medium-grey);
    box-sizing: border-box;
    border-radius: 10px;
    height: 40px;
    padding: 0;
    padding-left: 14px;
    display: flex;
    align-items: center;
    cursor: text;

    input {
      background-color: transparent;
      border: none;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: var(--dark-grey);
      outline: none;
      width: 100%;
      min-width: 0;
      padding: 0;

      &::placeholder {
        color: var(--medium-dark-grey);
      }
    }

    .Input-status {
      display: flex;
      justify-content: flex-end;
      width: 20px;

      .Input-valid-icon {
        display: none;
      }

      .Input-clear {
        display: none;

        button {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid var(--medium-grey);
          cursor: pointer;
          padding: 0;
          background-color: var(--light-grey);
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover, &:focus {
            background-color: white;
          }
        }
      }
    }

    &:hover {
      input {
        width: calc(100% - 32px);
      }

      .Input-status {
        .Input-valid-icon {
          display: none;
        }
        .Input-clear {
          display: initial;
        }
      }

      .react-datepicker-wrapper {
        width: calc(100% - 32px);
        input {
          width: 100%;
        }
      }

    }

  }

  .Input-label {
    font-family: 'Inter', sans-serif;
    color: var(--dark-grey);
    font-weight: 700;
    font-size: 13px;
  }

  &.Input-valid {
    .Input-container {
      background-color: var(--light-green-very-transparent);

      input {
        color: var(--light-green);
        width: calc(100% - 32px);
      }

      .Input-valid-icon {
        display: initial;
      }

      .react-datepicker-wrapper {
        width: calc(100% - 32px);
        input {
          width: 100%;
        }
      }
    }
  }

  &.Input-error {
    .Input-container {
      border-color: red;
    }
  }

  &.Input-disabled {
    pointer-events: none;
  }
}
