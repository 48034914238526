.Notifications {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;

  .Notifications-messages {
    margin-bottom: 100px;

    .Notifications-message {
      pointer-events: initial;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 44px;
      background-color: white;
      font-size: 15px;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      color: var(--dark-grey);
      margin-top: 16px;

      svg {
        margin-right: 16px;
      }

      &.Notifications-message-error {
        background-color: #FCEDEB;
        color: #C71A1A;
      }
    }
  }
}
