.Button {
  border: none;
  background-color: var(--blue);
  box-shadow: 0 10px 10px #04477029;
  height: 40px;
  padding: 0 24px;
  border-radius: 10px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;

  &:hover, &:active, &:focus {
    background-image: linear-gradient(#ffffff10, #ffffff10);
  }

  &.Button-ghost {
    background-color: transparent;
    color: var(--dark-grey);
    box-shadow: none;
  }

  &:disabled {
    cursor: default;
    background-image: linear-gradient(#d3d3d380, #d3d3d380);
  }
}
