.ErrorMessage {
  padding: 20px 26px 16px;

  .ErrorMessage-modal-error {
    margin-bottom: 8px;
    display: flex;

    p {
      color: #FF0000D0;
      border: 1px solid #FF000060;
      border-radius: 5px;
      padding: 8px;
    }
  }

  .ErrorMessage-modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    grid-gap: 8px;
  }
}
