body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

p {
  font-weight: 500;
  font-size: 16px;
}

p, h1, h2, h3, h4 {
  font-family: 'Inter', sans-serif;
  margin: 0;
  color: var(--dark-grey);
}

button {
  outline: none;
  cursor: pointer;
}

:root {
  --blue: #087CC4;
  --light-blue: #E5F2F9;
  --super-light-blue: #E8F0FA;
  --border-blue: #7EB5D6;
  --red: #E63232;
  --green: #4CA232;
  --light-green: #359089;
  --light-green-transparent: #35908980;
  --light-green-more-transparent: #3590891A;
  --light-green-very-transparent: #3590890D;
  --light-grey: #F8F8F8;
  --medium-light-grey: #EDEDED;
  --medium-grey: #D6D6D6;
  --medium-dark-grey: #A7A7A7;
  --dark-grey: #707070;
  --super-dark-grey: #414141;
  --background-grey: #ECEDEB;
  --orange: #FF9320;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Inter-Regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Inter-Bold.woff2") format("woff2");
}

// @font-face {
//   font-family: 'Inter';
//   font-style: normal;
//   // font-weight: 400 500 700;
//   font-display: swap;
//   src: url("/fonts/Inter.ttf") format("truetype");
// }
