.InputRadio {
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  label {
    margin-left: 8px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: var(--super-dark-grey);
    cursor: pointer;
  }

  .InputRadio-circle {
    width: 16px;
    height: 16px;
    border: 1px solid var(--dark-grey);
    border-radius: 50%;
    background-color: white;
    box-sizing: border-box;
    position: relative;

    &.InputRadio-circle-checked {
      background-color: var(--blue);
      border: none;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border: 1px solid white;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
      }
    }
  }

  &.InputRadio-disabled {
    cursor: default;

    .InputRadio-circle {
      background-color: var(--medium-light-grey);
      border-color: var(--medium-grey);
    }
  }

}
