.RangePicker-root {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  user-select: none;

  .RangePicker-bar {
    height: 4px;
    width: 98%;
    left: 1%;
    position: relative;
    background-color: var(--medium-dark-grey);
  }

  .RangePicker-marker {
    height: 20px;
    width: 4px;
    background-color: var(--medium-dark-grey);
    position: absolute;
    color: var(--dark-grey);
    font-size: 13px;

    &.RangePicker-marker-min {
      left: 0.7%;

      &::after {
        content: 'min';
        position: relative;
        top: 20px;
      }
    }

    &.RangePicker-marker-max {
      left: 98.8%;

      &::after {
        content: 'max';
        position: relative;
        top: 20px;
        right: 22px;
      }
    }

    &.RangePicker-marker-average {
      > div {
        position: relative;
        > span {
          position: absolute;
          top: 20px;
          transform: translateX(-44%);
        }
      }
    }
  }

  .RangePicker-dot {
    position: absolute;
    height: 18px;
    transform: translateY(-40%);
    z-index: 1;

    &::after {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: var(--blue);
      cursor: pointer;
      position: absolute;
      transform: translateX(-50%);
    }

    &:hover {
      &::after {
        background-color: var(--border-blue);
      }
    }
  }
}
