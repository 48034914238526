.AdhocResult {
  flex: 1;

  .AdhocResult-title {
    display: flex;
    margin-bottom: 12px;
    height: 58px;

    .AdhocResult-title-number {
      width: 47px;
      height: 47px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--blue);
      font-size: 29px;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      margin-right: 16px;
    }

    h1 {
      margin: 0;
      font-weight: 700;
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      color: var(--super-dark-grey);
    }

    .AdhocResult-title-title {
      display: flex;
      align-items: center;
    }
  }

  .AdhocResult-body {
    background-color: white;
    border: 1px solid var(--medium-grey);
    display: flex;
    flex-flow: column;
    padding: 24px;

    max-height: calc(100vh - 248px);
    overflow-y: auto;
  }

  .AdhocResult-Results {
    h1 {
      font-size: 17px;
      font-weight: 700;
      color: var(--super-grey-dark);
      margin-bottom: 18px;
    }

    h2 {
      @extend h1;
      font-size: 16px;
    }

    button {
      color: var(--dark-grey);
      border: none;
      background-color: transparent;
      font-family: 'Inter', sans-serif;
      cursor: pointer;
      height: 20px;
      padding: 0 4px;
      font-size: 12px;
      font-weight: 400;
      &:hover {
        background-color: var(--medium-light-grey);
      }
    }

    .AdhocResult-Results-header {
      display: flex;
      justify-content: space-between;
    }

    .AdhocResult-Results-row {
      margin-bottom: 32px;
    }

    .AdhocResult-Results-graph {
      background-color: var(--light-grey);
      height: 300px;
      min-height: 300px;
      padding: 8px;
      max-width: calc(100% - 20px);
    }

    .AdhocResult-Results-3grid{
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 16px;
      .AdhocResult-Results-3grid-Col {
        position: relative;
        button.print-Button {
          position: absolute;
          right: 0px;
        }
      }
    }

    .AdhocResult-Results-details {
      background-color: var(--medium-light-grey);
      padding: 10px 5px;
      border: 1px var(--medium-grey) solid;
      
      h5 {
        margin: 0;
        font-size: 16px;
      }
      p {
        font-weight: 400;
        font-size: 12px;
      }
    }

    .AdhocResult-Results-info {
      display: grid;
      grid-template-columns: repeat(3, 140px);
      margin-bottom: 21px;

      > *:not(:last-child) {
        padding-right: 16px;
      }

      > *:not(:first-child) {
        padding-left: 16px;
        border-left: 1px solid var(--medium-dark-grey);
      }

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .AdhocResult-Results-info-future {
      .AdhocResult-Results-info:first-child {
        margin-bottom: 0;
      }
    }

    .AdhocResult-Results-prices {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);

      .AdhocResult-Results-price {
        background-color: var(--light-grey);
        min-height: 113px;
        padding: 36px 8px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .AdhocResult-Results-price-value {
          color: var(--super-dark-grey);
          margin-top: 6px;
          font-weight: 700;
          font-size: 20px;
        }

        p {
          font-weight: 400;
        }
      }

      .AdhocResult-Results-price-list {
        margin-top: 16px;
        margin-bottom: 32px;
        p {
          font-size: 13px;
        }
      }

      button {
        margin-top: 16px;
        padding: 2px 8px 4px;
        position: absolute;
        bottom: 16px;
        background-color: var(--medium-light-grey);
        &:hover {
          background-color: var(--medium-grey);
        }
      }
    }

    .AdhocResult-Results-status {
      margin-top: 30px;

      p {
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
      }

      .AdhocResult-Results-status-details {
        p:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
  }

  &.AdhocResult-small {
    flex: unset;

    .AdhocResult-title {
      cursor: pointer;

      .AdhocResult-title-title {
        display: none;
      }
    }
    .AdhocResult-body {
      display: none;
    }
  }

  .AdhocResult-Results-error {
    color: red;
    font-weight: 700;
  }

}

@media screen and (max-width: 1030px) {
  .AdhocResult {
    .AdhocResult-Results {
      .AdhocResult-Results-prices {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media print {
  .Header, .AdhocInput, .AdhocChoose {
    display: none;
  }

  .AdHoc {
    background-color: unset;

    .AdHoc-body {
      padding: unset;

      *:last-child {
        margin: unset;
      }
    }
  }

  .AdhocResult .AdhocResult-body {
    border: none;
    max-height: unset;
  }

  .AdhocResult .AdhocResult-title {
    display: none;
  }
}
