table {
  width: 100%;
  border-collapse: collapse;
  thead {
    th{
      background-color:  rgba(8, 124, 196, 0.1019607843);
      text-align: left;
      color: var(--blue);
      padding: 4px 0 4px 10px;
      &:nth-child(1) {
        border-right: 1px solid var(--blue);
        padding-left: 10px;
      }
      &:nth-child(2) {
        border-right: 1px solid var(--blue);
        padding-left: 10px;
      }
      &:nth-child(3) {
        border-right: 1px solid var(--blue);
        padding-left: 10px;
      }
    }
  }
  tbody {
    tr{
      &.MarketAnalysis-Car-Clickable:hover {
        cursor: pointer;
        td {
          background-color: var(--dark-grey);
          color:white;
          .MarketAnalysis-Car-InfoColumn-Sub{
            &.grey {
              color: white
            }
          }
        }
      }
    }
    td{
      background-color: var(--medium-light-grey);
      border-top: 5px solid white;
      height: 50px;
      &.picture {
        width: 0px;
        img {
          height: 50px;
          margin-top: 2px;
          margin-left: 2px;
        }
      }

      &:nth-child(2) {
        border-right: 1px solid var(--medium-dark-grey);
        padding-left: 10px;
      }
      &:nth-child(3) {
        border-right: 1px solid var(--medium-dark-grey);
        padding-left: 10px;
      }
      &:nth-child(4) {
        border-right: 1px solid var(--medium-dark-grey);
        padding-left: 10px;
      }
      &:nth-child(5) {
        padding-left: 10px;
      }
      .MarketAnalysis-Car-InfoColumn-Sub{
        font-size: 12px;
        &.grey {
          color: var(--dark-grey)
        }
        &.red {
          color: var(--red)
        }
        &.green {
          color: var(--green)
        }
      }
    }
  }
}

.AdhocResult-Results-Market-View{
  background-color: rgba(8, 124, 196, 0.1019607843);
  display: inline-block;
  margin-bottom: 10px;
  border: 1px solid var(--blue);
  border-radius: 5px;
  right: 0;
  position: absolute;
  button.toggle{
    padding: 5px 10px;
    margin: 2px;
    display: inline-block;
    height: 33px;
    color: var(--blue);
    font-weight: bold;
    border-radius: 5px;
  }
  button.active{
    background-color: var(--blue);
    color: white;
  }
  button.toggle:hover{
    background-color: white;
    color: var(--blue);
  }
}

.AdhocResult-Results-Market-3grid{
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 2fr 1fr 2fr;
  margin-bottom: 16px;
  .AdhocResult-Results-Market-3grid-Col {
    position: relative;
    button.print-Button {
      position: absolute;
      right: 0px;
    }
  }
}